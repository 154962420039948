import React from 'react';
import {
  Checkbox,
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
  Paper,
} from '@mui/material';
import { FixedSizeList } from 'react-window';
import {
  ProductImage,
  ProductBarcodeStatus,
  ProductPrice,
  ProductActions,
} from './components';
import { formatDate } from './utils';

const columnWidths = {
  checkbox: 60,
  image: 70,
  name: 250,
  category: 150,
  subcategory: 150,
  price: 100,
  barcode: 70,
  created: 150,
  updated: 150,
  actions: 150,
};

const RowRenderer = ({ index, style, data }) => {
  const { products, isMobile, onProductSelect, selectedProducts, onProductClick, onDeleteProduct, columnWidths } = data;
  const product = products[index]; // No offset for header row now

  return (
    <div style={{
        ...style,
        display: 'flex',
        padding: '10px',
        alignItems: 'center',
        boxSizing: 'border-box',
      }}
      onClick={() => onProductClick(product.id)}>
      <div style={{ width: columnWidths.checkbox }}>
        <Checkbox
          checked={selectedProducts.includes(product.id)}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            e.stopPropagation();
            const updatedSelected = selectedProducts.includes(product.id)
              ? selectedProducts.filter((id) => id !== product.id)
              : [...selectedProducts, product.id];
            onProductSelect(updatedSelected);
          }}
        />
      </div>
      {!isMobile && (
        <div style={{ width: columnWidths.image }}>
          <ProductImage product={product} imageData={data.productImages[product.id]} height={50} width={50} />
        </div>
      )}
      <div style={{ width: columnWidths.name }}>
        <Tooltip title={`SKU: ${product.sku}`} arrow>
          <Typography variant="body2">{product.product_name}</Typography>
        </Tooltip>
      </div>
      {!isMobile && <div style={{ width: columnWidths.category }}>{product.category || 'N/A'}</div>}
      {!isMobile && <div style={{ width: columnWidths.subcategory }}>{product.subcategory || 'N/A'}</div>}
      <div style={{ width: columnWidths.price }}>
        <ProductPrice prices={product.prices} />
      </div>
      {!isMobile && <div style={{ width: columnWidths.created }}>{formatDate(product.created_at)}</div>}
      {!isMobile && <div style={{ width: columnWidths.updated }}>{formatDate(product.updated_at)}</div>}
      <div style={{ width: columnWidths.actions }}>
        <ProductActions
          onEdit={() => onProductClick(product.id)}
          onDelete={() => onDeleteProduct(product)}
        />
      </div>
      <div style={{ width: columnWidths.barcode }}>
        <ProductBarcodeStatus product={product} />
      </div>
    </div>
  );
};

const ProductTable = ({
  products,
  productImages,
  selectedProducts,
  onProductSelect,
  onProductClick,
  onDeleteProduct,
  sortField,
  sortDirection,
  onSort, // Passed from ProductList
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // Dynamically adjust column widths for responsiveness
  const responsiveColumnWidths = isMobile
    ? {
      checkbox: 60,
      name: 'auto',
      price: 100,
      actions: 150,
      barcode: 70,
    }
    : columnWidths;

  // Handle "Select All" checkbox logic
  const isAllSelected = selectedProducts.length === products.length;
  const isIndeterminate = selectedProducts.length > 0 && selectedProducts.length < products.length;

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      // Select all products
      onProductSelect(products.map((product) => product.id));
    } else {
      // Deselect all products
      onProductSelect([]);
    }
  };

  return (
    <Paper elevation={3} style={{ overflow: 'hidden' }}>
      <div style={{ position: 'relative', height: '100%' }}>
        {/* Sticky Header */}
        <div
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: '#f4f4f4',
            display: 'flex',
            fontWeight: 'bold',
            padding: '10px',
            alignItems: 'center'
          }}
        >
          <div style={{ width: responsiveColumnWidths.checkbox }}>
            {/* "Select All" checkbox */}
            <Checkbox
              indeterminate={isIndeterminate}
              checked={isAllSelected}
              onChange={handleSelectAll}
            />
          </div>
          {!isMobile && <div style={{ width: responsiveColumnWidths.image }}>Image</div>}
          <div
            style={{ width: responsiveColumnWidths.name, cursor: 'pointer' }}
            onClick={() => onSort('name')}
          >
            Name {sortField === 'name' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </div>
          {!isMobile && (
            <div
              style={{ width: responsiveColumnWidths.category, cursor: 'pointer' }}
              onClick={() => onSort('category')}
            >
              Category {sortField === 'category' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
            </div>
          )}
          {!isMobile && (
            <div
              style={{ width: responsiveColumnWidths.subcategory, cursor: 'pointer' }}
              onClick={() => onSort('subcategory')}
            >
              Subcategory {sortField === 'subcategory' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
            </div>
          )}
          <div
            style={{ width: responsiveColumnWidths.price, cursor: 'pointer' }}
            onClick={() => onSort('price')}
          >
            Price {sortField === 'price' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
          </div>
          {!isMobile && (
            <div
              style={{ width: responsiveColumnWidths.created, cursor: 'pointer' }}
              onClick={() => onSort('created_at')}
            >
              Created {sortField === 'created_at' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
            </div>
          )}
          {!isMobile && (
            <div
              style={{ width: responsiveColumnWidths.updated, cursor: 'pointer' }}
              onClick={() => onSort('updated_at')}
            >
              Updated {sortField === 'updated_at' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
            </div>
          )}
          <div style={{ width: responsiveColumnWidths.actions }}>Actions</div>
          <div style={{ width: responsiveColumnWidths.barcode }}>Has Code</div>
        </div>
        <FixedSizeList
          height={500}
          width="100%"
          itemSize={70}
          itemCount={products.length}
          itemData={{
            products,
            productImages,
            selectedProducts,
            onProductSelect,
            onProductClick,
            onDeleteProduct,
            isMobile,
            columnWidths: responsiveColumnWidths,
          }}
        >
          {RowRenderer}
        </FixedSizeList>
      </div>
    </Paper>
  );
};

export default ProductTable;
