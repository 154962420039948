import React, { useState, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  Typography,
  IconButton,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid
} from '@mui/material';
import {
  Close as CloseIcon,
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material';

const ListMergeDialog = ({
  open,
  onClose,
  lists,
  currentList,
  currentListItems,
  onMerge,
  setGlobalAlert
}) => {
  // Dialog states
  const [selectedList, setSelectedList] = useState(null);
  const [showPartialMerge, setShowPartialMerge] = useState(false);

  // Partial merge states
  const [mergeQuantities, setMergeQuantities] = useState({});
  const [filterCategory, setFilterCategory] = useState('All');
  const [filterSubcategory, setFilterSubcategory] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');

  // Get unique categories and subcategories
  const categories = useMemo(() => {
    const cats = [...new Set(currentListItems.map(item => item.category).filter(Boolean))];
    return ['All', ...cats];
  }, [currentListItems]);

  const subcategories = useMemo(() => {
    let items = currentListItems;
    if (filterCategory !== 'All') {
      items = items.filter(item => item.category === filterCategory);
    }
    const subcats = [...new Set(items.map(item => item.subcategory).filter(Boolean))];
    return ['All', '(Blank)', ...subcats];
  }, [currentListItems, filterCategory]);

  // Filter items
  const filteredItems = useMemo(() => {
    return currentListItems.filter(item => {
      if (filterCategory !== 'All' && item.category !== filterCategory) return false;
      if (filterSubcategory !== 'All') {
        if (filterSubcategory === '(Blank)') {
          if (item.subcategory) return false;
        } else if (item.subcategory !== filterSubcategory) {
          return false;
        }
      }
      if (searchTerm) {
        const search = searchTerm.toLowerCase();
        return (
          item.productName.toLowerCase().includes(search) ||
          (item.sku && item.sku.toLowerCase().includes(search))
        );
      }
      return true;
    });
  }, [currentListItems, filterCategory, filterSubcategory, searchTerm]);

  // Initialize quantities on dialog open
  React.useEffect(() => {
    if (open && currentListItems) {
      const initialQuantities = currentListItems.reduce((acc, item) => {
        acc[item.productId] = item.quantity;
        return acc;
      }, {});
      setMergeQuantities(initialQuantities);
    }
  }, [open, currentListItems]);

  const handleClose = () => {
    setSelectedList(null);
    setShowPartialMerge(false);
    setFilterCategory('All');
    setFilterSubcategory('All');
    setSearchTerm('');
    onClose();
  };

  const handleMergeAll = async () => {
    if (!selectedList) return;

    try {
      await onMerge(selectedList.id, mergeQuantities);
      handleClose();
    } catch (error) {
      console.error('Error merging lists:', error);
      setGlobalAlert?.({ message: 'Error merging lists', severity: 'error' });
    }
  };

  const handleQuantityChange = (productId, value) => {
    const newValue = Math.max(0, Math.min(
      parseInt(value) || 0,
      currentListItems.find(item => item.productId === productId)?.quantity || 0
    ));
    setMergeQuantities(prev => ({ ...prev, [productId]: newValue }));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={showPartialMerge ? "md" : "sm"}
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Merge Into List
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {!showPartialMerge ? (
          // Simple merge view
          <>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Select a list to merge items into. You can merge all items or choose specific quantities.
            </Typography>
            <Autocomplete
              options={lists.filter(list => list.id !== currentList.id)}
              getOptionLabel={(option) => option.name}
              value={selectedList}
              onChange={(_, newValue) => setSelectedList(newValue)}
              renderInput={(params) => <TextField {...params} label="Select Target List" fullWidth />}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
            />
          </>
        ) : (
          // Partial merge view
          <>
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                Merging into: {selectedList?.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Adjust quantities for items you want to merge
              </Typography>
            </Box>

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Category</InputLabel>
                  <Select
                    value={filterCategory}
                    onChange={(e) => {
                      setFilterCategory(e.target.value);
                      setFilterSubcategory('All');
                    }}
                    label="Category"
                  >
                    {categories.map(category => (
                      <MenuItem key={category} value={category}>
                        {category === 'All' ? <em>All</em> : category}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Subcategory</InputLabel>
                  <Select
                    value={filterSubcategory}
                    onChange={(e) => setFilterSubcategory(e.target.value)}
                    label="Subcategory"
                  >
                    {subcategories.map(subcategory => (
                      <MenuItem key={subcategory} value={subcategory}>
                        {subcategory === 'All' || subcategory === '(Blank)' ?
                          <em>{subcategory}</em> : subcategory}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <TableContainer component={Paper} variant="outlined">
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Available</TableCell>
                    <TableCell align="right">Quantity to Merge</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredItems.map((item) => (
                    <TableRow key={item.productId}>
                      <TableCell>
                        <Typography variant="body2">{item.productName}</Typography>
                        <Typography variant="caption" color="text.secondary">
                          {item.sku}
                        </Typography>
                      </TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell align="right">
                        <TextField
                          type="number"
                          size="small"
                          value={mergeQuantities[item.productId] || 0}
                          onChange={(e) => handleQuantityChange(item.productId, e.target.value)}
                          inputProps={{
                            min: 0,
                            max: item.quantity,
                            style: { textAlign: 'right' }
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </DialogContent>

      <DialogActions>
        {!showPartialMerge ? (
          <>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={() => setShowPartialMerge(true)}
              endIcon={<ArrowForwardIcon />}
              disabled={!selectedList}
            >
              Partial Merge
            </Button>
            <Button
              onClick={handleMergeAll}
              variant="contained"
              color="primary"
              disabled={!selectedList}
            >
              Merge All Items
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => setShowPartialMerge(false)}
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
            <Button
              onClick={handleMergeAll}
              variant="contained"
              color="primary"
              disabled={!selectedList}
            >
              Merge Selected
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ListMergeDialog;
